// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bungee&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* variables.css */
:root {
  /* Font family */
  --font-family: "Bungee", sans-serif;
  --desc-font-family: "Poppins", sans-serif;

  /* Background colors */
  --background-color: #0D0D0D;
  --background-color2: #272728;
  
  /* --background-color: linear-gradient(180deg, #2A132F, #853C95); */

  /* Font colors */
  --font-color1: #ffffff;
  --font-color2: #ffffff;

  /* Button color */
  --button-color: #272728;

  --hero-font-size-mobile: 50px;
  --hero-font-size-tablet: 110px;
  --hero-font-size-desktop: 75px;

  /* Font sizes */
  --font-size-mobile: 13px;
  --font-size-tablet: 16px;
  --font-size-desktop: 18px;

  --head-font-size-mobile: 30px;
}

body {
  -webkit-user-select: none;
          user-select: none;
  font-family: var(--font-family);
  background: var(--background-color);
  overflow-x: hidden;
  width: 100%;
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA,kBAAkB;AAClB;EACE,gBAAgB;EAChB,mCAAmC;EACnC,yCAAyC;;EAEzC,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B;;EAE5B,mEAAmE;;EAEnE,gBAAgB;EAChB,sBAAsB;EACtB,sBAAsB;;EAEtB,iBAAiB;EACjB,uBAAuB;;EAEvB,6BAA6B;EAC7B,8BAA8B;EAC9B,8BAA8B;;EAE9B,eAAe;EACf,wBAAwB;EACxB,wBAAwB;EACxB,yBAAyB;;EAEzB,6BAA6B;AAC/B;;AAEA;EACE,yBAAiB;UAAjB,iBAAiB;EACjB,+BAA+B;EAC/B,mCAAmC;EACnC,kBAAkB;EAClB,WAAW;EACX,eAAe;AACjB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n/* variables.css */\n:root {\n  /* Font family */\n  --font-family: \"Bungee\", sans-serif;\n  --desc-font-family: \"Poppins\", sans-serif;\n\n  /* Background colors */\n  --background-color: #0D0D0D;\n  --background-color2: #272728;\n  \n  /* --background-color: linear-gradient(180deg, #2A132F, #853C95); */\n\n  /* Font colors */\n  --font-color1: #ffffff;\n  --font-color2: #ffffff;\n\n  /* Button color */\n  --button-color: #272728;\n\n  --hero-font-size-mobile: 50px;\n  --hero-font-size-tablet: 110px;\n  --hero-font-size-desktop: 75px;\n\n  /* Font sizes */\n  --font-size-mobile: 13px;\n  --font-size-tablet: 16px;\n  --font-size-desktop: 18px;\n\n  --head-font-size-mobile: 30px;\n}\n\nbody {\n  user-select: none;\n  font-family: var(--font-family);\n  background: var(--background-color);\n  overflow-x: hidden;\n  width: 100%;\n  max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
