import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import AudioPlayer from './AudioPlayer';
import Audio2 from './Audio2';

const AudioDivMain = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 100px 0px 0px;
    @media (max-width: 540px) {
       margin: 0px 0px 120px;
    }
`;

const Heading = styled.h2`
  width: 80%; 
  text-align: center;
  font-family: var(--font-family);
  font-size: 55px;
  line-height: 75px;
  margin-bottom: 50px;
  color: var(--font-color1);
  @media (max-width: 1028px) {
    font-size: 45px;
    line-height: 60px;
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
    // margin-bottom: 0px;
    line-height: 60px;
  }
  @media (max-width: 540px) {
    text-align: center;
    font-size: 28px;
    line-height: 35px;
    margin: 0px 10px 40px;
    width: 100%;
    padding: 0px 20px 0px;
  }
`;

const Audio = () => {

    return (
        <AudioDivMain>
            <Heading>McAfee’s-Dog Crypto Mix</Heading>
            {/* <AudioPlayer audioSrc='/audio/Audio.mp3' /> */}
            <Audio2 src='/audio/Audio.mp3' />
        </AudioDivMain>
    );
};

export default Audio;