import React from 'react';
import styled from 'styled-components';

const RoadmapSection = () => {
  return (
    <Section id='roadmap'>
      <Title>McAfee's-Dog's
        Masterplan</Title>
      <PhaseContainer>
        <PhaseBoxTopLeft>
          <PhaseTitle>Phase 1: The Puppy Begins</PhaseTitle>
          <PhaseDescription>
            In Phase 1, McAfee's-Dog is a curious, mischievous pup. Picture this fluffy troublemaker exploring the digital world with innocent enthusiasm. It's like McAfee's antivirus, only furrier and with a knack for chewing cables. We'll capture its puppy charm, like mistaking a USB stick for a chew toy.
          </PhaseDescription>
        </PhaseBoxTopLeft>
        <PhaseBoxTopRight>
          <PhaseTitle>Phase 2: Digital Doggo</PhaseTitle>
          <PhaseDescription>
            In Phase 2, get ready for McAfee's-Dog, your cyber sidekick! This digital dynamo saves devices while grooving at virtual parties. Picture it in virtual sunglasses, hacking fake emails, and celebrating victories with epic dances. Cybersecurity with enthusiastic tail wags—adorable and effective. Who wouldn't want their antivirus to be this cool?
          </PhaseDescription>
        </PhaseBoxTopRight>
        <PhaseBoxBottomLeft className="bottom">
          <InnerPhaseBox>
            <PhaseTitle>Phase 3: Guardian of the Internet</PhaseTitle>
            <PhaseDescription>
              In Phase 3, McAfee's-Dog becomes the Guardian of the Internet, equipped with cybernetic enhancements. Picture memes of it fending off cyberattacks, standing tall against phishing attempts, and leading digital dogs to protect online privacy. Superhero antics mixed with canine cuteness, making everyone cheer for our four-legged cybersecurity champion.
            </PhaseDescription>
          </InnerPhaseBox>
        </PhaseBoxBottomLeft>
      </PhaseContainer>
    </Section>
  );
};

const Section = styled.section`
  padding: 120px 20px 50px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 991px) {
    padding: 90px 50px 0px;
  }
  @media (max-width: 540px) {
    padding: 0px 30px 0px;
  }
`;

const Title = styled.h2`
  width: 80%; 
  text-align: center;
  font-family: var(--font-family);
  font-size: 55px;
  line-height: 75px;
  margin-bottom: 40px;
  color: var(--font-color1);
  @media (max-width: 1028px) {
    font-size: 45px;
    line-height: 60px;
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
    // margin-bottom: 0px;
    line-height: 60px;
  }
  @media (max-width: 540px) {
    text-align: center;
    font-size: 28px;
    line-height: 35px;
    margin: 0px 10px 50px;
    width: 100%;
    padding: 0px 20px 0px;
  }
`;

const PhaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
  max-width: 800px;
  @media (max-width: 991px) {
    gap: 0;
  }
  @media (max-width: 540px) {
    gap: 0;
  }
`;
const InnerPhaseBox = styled.div`
&::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: linear-gradient(180deg, #FFFFFF, #999999);
  border-radius: 50%;
  left: -10px;
  bottom: -10px;
}
&::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: linear-gradient(180deg, #FFFFFF, #999999);
  border-radius: 50%;
  right: -10px;
  bottom: -10px;
}
@media (max-width: 540px) {
  &::before {
    width: 15px!important;
    height: 15px!important;
  }
  &::after {
    width: 15px!important;
    height: 15px!important;
  }
}
`;

const PhaseBox = styled.div`
  padding: 40px 60px;
  position: relative;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: linear-gradient(180deg, #FFFFFF, #999999);
    border-radius: 50%;
    left: -10px;
    top: -10px;
    @media (max-width: 540px) {
      width: 15px!important;
      height: 15px!important;
    }
  }
  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: linear-gradient(180deg, #FFFFFF, #999999);
    border-radius: 50%;
    right: -10px;
    top: -10px;
    @media (max-width: 540px) {
      width: 15px!important;
      height: 15px!important;
    }
  }

  @media (max-width: 540px) {
    height: unset;
    padding: 20px;
    &::before {
      width: 15px!important;
      height: 15px!important;
    }
    &::after {
      width: 15px!important;
      height: 15px!important;
    }
  }
`;

const PhaseBoxTopLeft = styled(PhaseBox)`
 background: linear-gradient(270deg, var(--background-color), var(--background-color2));
  margin-bottom: 0;
  border-top: 2px dashed #ffffff;
  border-left: 2px dashed #ffffff;
`;

const PhaseBoxTopRight = styled(PhaseBox)`
background: linear-gradient(270deg, var(--background-color2), var(--background-color));
  margin-bottom: 0;
  border-top: 2px dashed #ffffff;
  border-right: 2px dashed #ffffff;
`;

const PhaseBoxBottomLeft = styled(PhaseBox)`
background: linear-gradient(270deg, var(--background-color), var(--background-color2));
  margin-bottom: 0;
  border-top: 2px dashed #ffffff;
  border-bottom: 2px dashed #ffffff;
  border-left: 2px dashed #ffffff;
  &.bottom::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: linear-gradient(180deg, #FFFFFF, #999999);
    border-radius: 50%;
    left: -10px;
    bottom: -10px;
    @media (max-width: 540px) {
      width: 15px!important;
      height: 15px!important;
    }
  }
  &.bottom::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: linear-gradient(180deg, #FFFFFF, #999999);
    border-radius: 50%;
    right: -10px;
    bottom: -10px;
    @media (max-width: 540px) {
      width: 15px!important;
      height: 15px!important;
    }
  }
  @media (max-width: 540px) {
    &.bottom::before {
      width: 15px!important;
      height: 15px!important;
    }
    &.bottom::after {
      width: 15px!important;
      height: 15px!important;
    }
  }
`;

const PhaseTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
   @media (max-width: 540px) {
      font-size: 22px;
   }
`;

const PhaseDescription = styled.p`
  font-size: 16px;
  line-height: 1.6;
  font-family: var(--desc-font-family);
`;

export default RoadmapSection;