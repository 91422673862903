import * as React from "react";
import styled from "styled-components";
import { telegramurl, twitterurl, dextool, uniswapurl, linktreeurl } from '../../url';

const data = [
  {
    id: 1,
    image: "/img/icon.png",
    url: uniswapurl
  },
  {
    id: 1,
    image: "/img/twitter.png",
    url: twitterurl
  },
  {
    id: 2,
    image: "/img/telegram.png",
    url: telegramurl
  },
  {
    id: 3,
    image: "/img/Dextool.png",
    url: dextool
  },
  {
    id: 3,
    image: "/img/linktree.png",
    url: linktreeurl
  }
];

function MyComponent() {

  const handleBuyNowClick = () => {
    window.open(uniswapurl, '_blank');
  };

  const handleSocialLinkClick = (url) => {
    window.open(url, '_blank'); // Open the URL in a new tab
  };

  return (
    <MainContainer>
      <FooterContainer>
        <SocilMediaSection>
          <SocialIcons>
            {data.map((item) => (
              item.url && (
                <ImageDiv onClick={() => handleSocialLinkClick(item.url)}>
                  <Image src={item.image} alt="footer image" />
                </ImageDiv>
              )
            ))}
          </SocialIcons>
        </SocilMediaSection>
        <MemeImage src="/img/footer.png" alt="coin" loading="lazy" />
        <Button onClick={handleBuyNowClick}>
          Buy $MDOG Now
        </Button>
      </FooterContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  color: var(--Font-color, #fff);
  font-weight: 400;
  padding: 20px 0px 20px;
  width: 100%;
  align-items: center;
  // background-image: url('/img/footerbg.png'); /* Specify the path to your background image */
  // background-size: cover; /* Ensure the background image covers the entire container */
  // background-position: center; /* Center the background image */
  // background-repeat: no-repeat;
  @media (max-width: 1028px) {
    // padding: 190px 0px 0px;
  }
  @media (max-width: 991px) {
    margin-top: 80px;
    padding: 20px 0px 20px;
  }
  @media (max-width: 540px) {
    // padding: 80px 0px 0px;
    margin-top: 20px;
  }
`;

const FooterContainer = styled.footer`
  background: var(--button-color);
  position: relative;
  display: flex;
  width: 90%;
  gap: 20px;
  font-size: 18px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px;
  border-radius: 50px;
  // flex-direction: column;
  @media (max-width: 1028px) {
    padding: 0px 30px;
  }
  @media (max-width: 991px) {
    padding: 7px 15px;
    max-width: 100%;
    // justify-content: flex-end;
  }
  @media (max-width: 540px) {
    border-radius: 20px;
    flex-direction: column;
    margin-top: 0px;
    gap: 0px;
    padding: 7px 15px 15px;
  }
`;

const SocilMediaSection = styled.div`
width: 50%;
display: flex;
align-items: center;
justify-content: space-between;
// padding-bottom: 30px;
@media (max-width: 1028px) {
  // width: 15%;
}
@media (max-width: 540px) {
  width: 100%;
}
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  // margin: 0px 0px 30px;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  // filter: invert(1);
@media (max-width: 1028px) {
  // justify-content: center;
}
@media (max-width: 540px) {
  margin: 5px 5px 10px;
  justify-content: center;
}
`;

const ImageDiv = styled.div`
  // background-color: rgba(52, 17, 63, 0.5);
  margin: 5px;
  padding: 15px;
  border-radius: 10px;
  transition: ease-in-out 0.5s;
  // &:first-child {
  //   & img{
  //     transform: scale(1.3);
  //   }
  // }
  // &:nth-child(2) {
  //   & img{
  //     transform: scale(0.8);
  //   }
  // }
  @media (max-width: 991px) {
    padding: 10px;
  }
`;

const Image = styled.img`
  height: 25px;
  width: 25px;
  // margin: 0px 10px;
  max-width: 100%;
  // margin-top: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "72px" : "0")};
  cursor: pointer;
  @media (max-width: 991px) {
    margin-top: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "40px" : "0")};
  }
  @media (max-width: 540px) {
    height: 20px;
    width: 20px;
  }
`;

const MemeImage = styled.img`
  width: 13%;
  max-width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  z-index: 0;
  @media (max-width: 991px) {
    width: 18%;
  }
  @media (max-width: 540px) {
    left: unset;
    width: 20%;
    right: -15px;
    // width: 200px;
    // margin-top: 30px;
  }
`;

const Button = styled.button`
width: fit-content;
display: flex;
align-self: center;
background: var(--background-color);
padding: 10px 30px;
border-radius: 50px;
align-items: center;
// margin-top: 30px;
@media (max-width: 540px) {
    font-size: 15px;
    padding: 10px 20px;
}
`;

export default MyComponent;