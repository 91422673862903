import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const PlayerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: ${({ isPlaying }) => (isPlaying ? '#f44336' : '#4caf50')}; /* Red for pause, green for play */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
`;

const AudioPlayer = ({ audioSrc }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <PlayerContainer>
      <audio ref={audioRef} src={audioSrc} />
      <Button onClick={handlePlayPause} isPlaying={isPlaying}>
        {isPlaying ? 'Pause' : 'Play'}
      </Button>
    </PlayerContainer>
  );
};

export default AudioPlayer;