import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  @media (max-width: 1028px) {
    min-height: unset;
    margin-bottom: 100px;
  }
  @media (max-width: 540px) {
    // padding: 0px 20px 0px;
  }
`;

const Images = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media (max-width: 540px) {
    flex-direction: column;
  }
`;

const SidePanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 20%;
  align-items: center;
  @media (max-width: 540px) {
    flex-direction: row;
    overflow: auto;
    width: 100%;
    margin: 0px 0px 30px 0px;
    gap: 10px;
    padding: 30px 10px 0px;
  }
`;

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${({ isSelected }) => (isSelected ? 'white' : 'var(--background-color2)')};
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
  border: ${({ isBackgroundSelected }) => (isBackgroundSelected ? '3px solid white' : 'none')};
  @media (max-width: 540px) {
    width: 150px;
    height: 70px;
  }
`;

const Image = styled.img`
  max-width: 120%;
  max-height: 120%;
  object-fit: contain;
  position: absolute;
  bottom: 0;
  border-radius: 7px;
  overflow: auto;
`;

const CenterPanel = styled.div`
  height: 650px;
  background-image: url('/img/bg.png');
  background-size: cover;
  background-position: center;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-end;
  transition: background-image 0.5s ease;
  width: 60%;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 540px) {
    width: 90%;
    height: 350px;
  }
`;

const OverlayImage = styled.img`
  width: 550px;
  height: 450px
  position: absolute;
  bottom: 0px;
  left: 0%;
  object-fit: contain;
  z-index: 3;
  @media (max-width: 540px) {
  width: 500px;
  height: 300px
  }
`;

const OverlayBackground = styled.img`
  position: absolute;
  bottom: 0px;
  right: 150px;
  width: 300px;
  height: 450px;
  margin-bottom: -100px;
  object-fit: contain;
  z-index: 9;
  overflow: hidden;
  @media (max-width: 1028px) {
    width: 250px;
    height: 350px;
    right: 40px;
    margin-bottom: -50px;
  }
      @media (max-width: 540px) {
  width: 150px;
  height: 250px
  }
`;

const Heading = styled.h2`
  width: 80%; 
  text-align: center;
  font-family: var(--font-family);
  font-size: 55px;
  line-height: 75px;
  margin-bottom: 50px;
  color: var(--font-color1);
  @media (max-width: 1028px) {
    font-size: 45px;
    line-height: 60px;
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
    // margin-bottom: 0px;
    line-height: 60px;
  }
  @media (max-width: 540px) {
    text-align: center;
    font-size: 28px;
    line-height: 35px;
    margin: 0px 10px 0px;
    width: 100%;
    padding: 0px 20px 0px;
  }
`;

const Description = styled.p`
  text-align: center;
  color: var(--font-color1);
  width: 80%;
  margin-bottom: 100px;
  font: 18px var(--desc-font-family);
  line-height: 25px;
  @media (max-width: 1028px) {
  }
  @media (max-width: 991px) {
    margin-top: 40px;
  }
  @media (max-width: 540px) {
    margin-bottom: 25px;
    font-size: 16px;
    width: 100%;
    text-align: justify;
    padding: 0px 20px 0px;
  }
`;

const images = [
  '/img/1.png',
  '/img/2.png',
  '/img/3.png',
  '/img/4.png',
  '/img/5.png'
];

const backgrounds = [
  '/img/bg1.png',
  '/img/bg2.png',
  '/img/bg3.png',
  '/img/bg4.png',
  '/img/bg5.png'
];

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [selectedBackground, setSelectedBackground] = useState(backgrounds[0]);

  return (
    <Container id='gallery'>
      <Heading>Bark-tastic Laughs</Heading>
      <Description>
        Welcome to the McAfee's-Dog Gallery, where memes come to life in the most pawsome ways possible! Get ready to have your funny bone tickled as you wander through our hilarious exhibits. From dogs in tutus to unicycles, our artists have turned internet humor into a masterpiece of giggles and grins. You might find yourself laughing so hard you start wagging your own tail! So, unleash your inner meme lover and dive into the wacky world of McAfee's-Dog Gallery. It's a bark-tacular experience you won't soon forget!
      </Description>
      <Images>
        <SidePanel>
          {images.map((img, index) => (
            <ImageWrapper key={index} onClick={() => setSelectedImage(img)} isSelected={selectedImage === img}>
              <Image src={img} alt={`Image ${index + 1}`} isSelected={selectedImage === img} />
            </ImageWrapper>
          ))}
        </SidePanel>
        <CenterPanel bg='/img/bg.jpeg'>
          <OverlayBackground src={selectedBackground} alt="Selected Image" />
          <OverlayImage src={selectedImage} alt="Selected Image" />
        </CenterPanel>
        <SidePanel>
          {backgrounds.map((bg, index) => (
            <ImageWrapper key={index} onClick={() => setSelectedBackground(bg)} isSelected={selectedBackground === bg}>
              <Image src={bg} alt={`Background ${index + 1}`} isSelected={selectedBackground === bg} />
            </ImageWrapper>
          ))}
        </SidePanel>

      </Images>
    </Container>
  );
}

export default Gallery;