import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { telegramurl, twitterurl, dextool, uniswapurl, linktreeurl } from '../../url';

const menuItems = [
  { label: "What is meme coin?", link: "#about" },
  { label: "Gallery", link: "#gallery" },
  { label: "Roadmap", link: "#roadmap" },
  { label: "Tokenomics", link: "#tokenomics" },
];

function Header() {
  const [isMenuOpenNav, setIsMenuOpenNav] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRefNav = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if clicked outside of it
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleMenuClose();
      }
    };

    // Add event listener to the window when the dropdown is open
    if (isMenuOpen) {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const handleClickOutsideNav = (event) => {
      // Close the dropdown if clicked outside of it
      if (dropdownRefNav.current && !dropdownRefNav.current.contains(event.target)) {
        handleMenuCloseNav();
      }
    };

    // Add event listener to the window when the dropdown is open
    if (isMenuOpenNav) {
      window.addEventListener('click', handleClickOutsideNav);
    }

    return () => {
      window.removeEventListener('click', handleClickOutsideNav);
    };
  }, [isMenuOpenNav]);

  const isMobileOrTablet = () => {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return screenWidth <= 1028;
  }
  const isMobileOrTabletScreen = isMobileOrTablet();

  const toggleMenuNav = () => {
    // setIsMenuOpenNav(!isMenuOpen); 
    setIsMenuOpenNav((prevIsMenuOpenNav) => !prevIsMenuOpenNav);
  };

  // Function to toggle the dropdown
  const handleMenuToggle = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  // Function to close the dropdown
  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuCloseNav = () => {
    setIsMenuOpenNav(false);
  };

  const scrollToSection = (e, link) => {
    e.preventDefault();
    const section = document.querySelector(link);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <HeaderWrapper>
        {isMobileOrTabletScreen ? (
          <>
            <LogoDiv>
              <Logo src="/img/Logo.png" alt="Logo" />
            </LogoDiv>
            <SocialMedia>
              <DropdownContent>
                {uniswapurl && (
                  <DropdownItem href={uniswapurl} target="_blank">
                    <img className="bigimg" src="/img/icon.png" alt="uniswap" style={{ marginRight: '15px', width: '35px', height: '35px' }} />
                  </DropdownItem>
                )}
                {twitterurl && (
                  <DropdownItem href={twitterurl} target="_blank">
                    <img src="/img/twitter.png" alt="twitter" style={{ marginRight: '15px', width: '25px', height: '25px' }} />
                  </DropdownItem>
                )}
                {telegramurl && (
                  <DropdownItem href={telegramurl} target="_blank">
                    <img src="/img/telegram.png" alt="telegram" style={{ marginRight: '15px', width: '25px', height: '25px' }} />
                  </DropdownItem>
                )}
                {dextool && (
                  <DropdownItem href={dextool} target="_blank">
                    <img src="/img/dextool.png" alt="dextool" style={{ marginRight: '15px', width: '25px', height: '25px' }} />
                  </DropdownItem>
                )}
                {linktreeurl && (
                  <DropdownItem href={linktreeurl} target="_blank">
                    <img src="/img/linktree.png" alt="linktree" style={{ marginRight: '15px', width: '25px', height: '25px' }} />
                  </DropdownItem>
                )}
              </DropdownContent>
            </SocialMedia>
            <HamberburgerDiv ref={dropdownRefNav}>
              <Menu src="/img/menu.png" alt="menu" onClick={toggleMenuNav} />
              <TabNavDiv isOpenNav={isMenuOpenNav}>
                <ArrowIcon />
                <NavTab>
                  {[menuItems].map((menu, menuIndex) =>
                    menu.map((item, itemIndex) => (
                      <NavItem key={`${menuIndex}-${itemIndex}`}>
                        <NavLink href={item.link} onClick={(e) => scrollToSection(e, item.link)}>{item.label}</NavLink>
                      </NavItem>
                    ))
                  )}
                </NavTab>
              </TabNavDiv>
            </HamberburgerDiv>
          </>
        ) : (
          <>
            <LogoDiv>
              <Logo src="/img/Logo.png" alt="Logo" />
            </LogoDiv>
            <SocialMedia>
              <DropdownContent>
                {uniswapurl && (
                  <DropdownItem href={uniswapurl} target="_blank">
                    <img src="/img/icon.png" alt="uniswap" style={{ margin: '5px', width: '35px', height: '35px' }} />
                  </DropdownItem>
                )}
                {twitterurl && (
                  <DropdownItem href={twitterurl} target="_blank">
                    <img src="/img/twitter.png" alt="twitter" style={{ margin: '5px', width: '25px', height: '25px' }} />
                  </DropdownItem>
                )}
                {telegramurl && (
                  <DropdownItem href={telegramurl} target="_blank">
                    <img src="/img/telegram.png" alt="telegram" style={{ margin: '5px', width: '25px', height: '25px' }} />
                  </DropdownItem>
                )}
                {dextool && (
                  <DropdownItem href={dextool} target="_blank">
                    <img src="/img/Dextool.png" alt="dextool" style={{ margin: '5px', width: '25px', height: '25px' }} />
                  </DropdownItem>
                )}
                {linktreeurl && (
                  <DropdownItem href={linktreeurl} target="_blank">
                    <img src="/img/linktree.png" alt="linktree" style={{ margin: '5px', width: '25px', height: '25px' }} />
                  </DropdownItem>
                )}
              </DropdownContent>
            </SocialMedia>
            <Nav1>
              {menuItems.map((item, index) => (
                <NavItem key={index}>
                  <NavLink href={item.link} onClick={(e) => scrollToSection(e, item.link)}>{item.label}</NavLink>
                </NavItem>
              ))}
            </Nav1>
          </>
        )}
      </HeaderWrapper>
    </>
  );
}

const ArrowIcon = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--background-color);
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 540px) {
    left: unset;
    right: 15%;
  }
`;

const HeaderWrapper = styled.header`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  padding: 30px 40px;
  font-size: 24px;
  color: var(--font-color1);
  font-weight: 400;
  z-index: 999;
  @media (max-width: 1028px) {
  padding: 15px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 540px) {
    justify-content: space-around;
  }
`;

const LogoDiv = styled.div`
  width: 33%;
  display: flex;
  justify-content: flex-start;
@media (max-width: 1028px) {
  width: 25%;
}
@media (max-width: 540px) {
  // width: 33%;
}
`;

const Logo = styled.img`
  width: 30%;
  max-width: 100%;
  object-fit: contain;
  @media (max-width: 1028px) {
    width: 150px;
  }
  @media (max-width: 991px) {
    width: 130px;
  }
  @media (max-width: 540px) {
    width: 85%;
    margin-left: 0px;
    margin-top: 0px;
  }
`;

const Menu = styled.img`
  width: 20px;
  // max-width: 100%;
  // aspect-ratio: 2.22;
  object-fit: contain;
  // padding-left: 10px;
  @media (max-width: 1028px) {
    // margin-top: 20px;
    width: 50px;
  }
  @media (max-width: 991px) {
    width: 50px;
  }
  @media (max-width: 540px) {
       width: 35px;
  }
`;

const Nav1 = styled.nav`
display: flex;
gap: 5px;
width: 33%;
justify-content: center;
flex-direction: column;
align-items: flex-end;
padding-top: 20px;
@media (max-width: 1028px) {
  margin-left: 0px;
  margin-top: 25px;
  gap: 35px;
}
@media (max-width: 991px) {
  margin-top: 20px;
  gap: 20px;
  margin-left: 0px;
  justify-content: center;
}
@media (max-width: 540px) {
  margin-top: 20px;
  gap: 1px 30px;
  display: flex;
  flex-wrap: wrap;
}
`;

const NavItem = styled.li`
  list-style: none;
  @media (max-width: 991px) {
    font-size: 14px;
  }
`;

const NavLink = styled.a`
  font-family: var(--font-family);
  text-decoration: none;
  color: #ffffff;
  font-size: var(--font-size-desktop);
  @media (max-width: 1028px) {
    font-size: 16px;
  }
  @media (max-width: 991px) {
    font-size: 14px;
  }
  @media (max-width: 540px) {
    font-size: 10px;
  }
`;

const SocialMedia = styled.div`
 width: 33%;
 display: flex;
 justify-content: flex-end;
 position: relative;
 background-color: var(--background-color);
  border-radius: 50px;
  padding: 12px 15px;
 @media (max-width: 1028px) {
  width: 50%;
}
  @media (max-width: 540px) {
  // width: 10%;
  margin-top: 0px;
  margin-left: 0px;
}
`;

const DropdownContent = styled.div`
  display: flex;
  color: white;
  width: 100%;
  filter: invert(1);
  // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999;
  // padding: 10px 20px 5px;
  justify-content: space-between;
  @media (max-width: 1028px) {
   padding: 5px 20px 5px;
  // justify-content: space-evenly;
  }
  @media (max-width: 540px) {
   padding: 5px 0px 5px;
    // justify-content: center;
  }
`;

const DropdownItem = styled.a`
display: flex;
align-items: center;
// padding: 8px 15px;
text-decoration: none;
color: white;
font-size: 16px;
transition: transform 0.2s ease-in-out;
& img{
  filter: invert(1);
  @media (max-width: 540px) {
    width: 17px!important;
    height: 17px!important;
    margin-right: unset;
    margin: 0px 5px;
  }
}
.bigimg{
  @media (max-width: 540px) {
    width: 25px!important;
    height: 25px!important;
  }
}
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: 991px) {
    font-size: 14px;
  }
  @media (max-width: 540px) {
    font-size: 12px;
    padding: 0px;
  }
`;

const NavTab = styled.nav`
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out; 
  & > li {
    list-style-type: disc;
    font-size: 12px; 
  }
  @media (max-width: 540px) {
    & > li {
      font-size: 7px; 
    }
  }
`;

const TabNavDiv = styled.nav`
  display: ${({ isOpenNav }) => (isOpenNav ? 'block' : 'none')};
  position: absolute;
  background-color: var(--background-color);
  color: white;
  width: fit-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  top: calc(100% + 10px); 
  right: 10px;
  z-index: 999999;
  border-radius: 10px;
  padding: 10px 10px 10px;
  @media (max-width: 540px) {
    padding: 5px;
    min-width: 140px;
    right: 0px;
    top: calc(100% + 15px); 
  }
 
`;

const HamberburgerDiv = styled.div`
width: 25%;
display: flex;
justify-content: flex-end;
position: relative;
@media (max-width: 1028px) {
  width: 25%;
  justify-content: center;
}
@media (max-width: 540px) {
  width: 20%;
}
`;

export default Header;
