import * as React from "react";
import styled, { keyframes } from "styled-components";

function MemeExplanation() {
  return (
    <ExplanationSection id="about">
      <ImageContainer>
        <MemeImage1 src="/img/Coin.png" alt="coin" loading="lazy" />
      </ImageContainer>
      <TextContainer>
        <Heading> Laugh, Bark, and 
        Invest </Heading>
        <Description>
        Introducing McAfee's-Dog, the meme coin that's got more bark than bite! If you thought Dogecoin was the alpha of meme coins, think again. Imagine if John McAfee's eccentricity was distilled into a digital currency - that's McAfee's-Dog in a nutshell. McAfee's-Dog is here to fetch your attention with a wagging tail and a snarky grin. Imagine a world where your cryptocurrency is as loyal as your furry friend, and just as unpredictable! Our coin may not chew your slippers, but it will definitely chew up the competition. So, why chase boring investments when you can roll over with McAfee's-Dog and play fetch with some serious fun? Join the pack and let’s make crypto a pawsome experience! 
        </Description>
      </TextContainer>
    </ExplanationSection>
  );
}

const ExplanationSection = styled.section`
  display: flex;
  align-items: center;
  align-self: center;
  color: var(--font-color1);
  font-weight: 400;
  width: 100%;
  justify-content: center;
  position: relative;
  margin: auto;
  min-height: 100vh;
  @media (max-width: 1028px) {
    margin-top: 150px;
    margin-bottom: 150px;
    padding: 0px;
    min-height: unset;
  }
  @media (max-width: 991px) {
    margin-top: 50px;
  }
  @media (max-width: 540px) {
    flex-direction: column-reverse;
    margin-top: 40px;
    margin-bottom: 100px;
    padding: 0px 20px 0px;
  }
`;

const TextContainer = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  padding-right: 60px;
  @media (max-width: 1028px) {
  padding-right: 20px;
  width: 70%;
  }
  @media (max-width: 991px) {
    padding-left: 0px;
  }
  @media (max-width: 540px) {
  padding-right: 0px;
  width: 100%;
  }
`;

const Heading = styled.h2`
  text-align: right;
  margin-bottom: 10px;
  color: var(--font-color1);
  font-family: var(--font-family);
  font-size: 55px;
  line-height: 75px;
  @media (max-width: 1028px) {
    font: 45px var(--font-family);
    // width: 70%;
    line-height: 70px;
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
    margin-bottom: 0px;
    line-height: 60px;
  }
  @media (max-width: 540px) {
    text-align: center;
    font-size: 28px;
    line-height: 35px;
    margin: 0px 10px 0px;
  }
`;

const Description = styled.p`
  text-align: right;
  color: var(--font-color1);
  // width: 60%;
  font: 18px var(--desc-font-family);
  line-height: 25px;
  @media (max-width: 1028px) {
    // width: 65%;
  }
  @media (max-width: 991px) {
    margin-top: 40px;
  font: 16px var(--desc-font-family);
  }
  @media (max-width: 540px) {
    font-size: 16px;
    width: 100%;
    text-align: justify;
  }
`;

const ImageContainer = styled.div`
  width: 35%;
  display: flex;
  position: relative;
  justify-content: center;
  @media (max-width: 1028px) {
    width: 30%;
  }
  @media (max-width: 540px) {
    width: 100%;
    margin-top: 30px;
  }
`;

const MemeImage1 = styled.img`
  width: 100%;
  margin-left: -130px;
  @media (max-width: 1028px) {
    margin-left: 0px;
    // width: 300px;
  }
  @media (max-width: 991px) {
    margin-left: -60px;
  }
  @media (max-width: 540px) {
    margin-left: -20px;

  }
`;

export default MemeExplanation;