import * as React from "react";
import styled from "styled-components";
import { telegramurl, twitterurl, dextool, uniswapurl, linktreeurl } from '../../url';

const data = [
  {
    id: 1,
    image: "/img/telegram.png",
    title: 'telegramurl',
    url: telegramurl
  },
];

function MyComponent() {

  const handleJoinNowClick = () => {
    window.open(telegramurl, '_blank');
  };

  const handleSocialLinkClick = (url) => {
    window.open(url, '_blank'); // Open the URL in a new tab
  };

  return (
    <MainContainer id="footer">
      <FooterContainer>
        <LineCoin>
          <Title>JOIN OUR COMMUNITY</Title>
        </LineCoin>
        <SocilMediaSection>
        <Title1>STAY CONNECTED TO McAfee'S DOG</Title1>
          {/* <SocialIcons>
            {data.map((item) => (
              item.url && (
                <ImageDiv onClick={() => handleSocialLinkClick(item.url)}>
                  <Image src={item.image} alt="footer image" />
                </ImageDiv>
              )
            ))}
          </SocialIcons> */}
          <Button onClick={handleJoinNowClick}>
            <Image src='/img/telegram.png' alt="footer image" />
            Join now
          </Button>
        </SocilMediaSection>
      </FooterContainer>
    </MainContainer>
  );
}

const MainContainer = styled.section`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  color: var(--Font-color, #fff);
  font-weight: 400;
  padding: 80px 0px 80px;
  width: 90%;
  margin: auto;
  margin-top: 150px;
  margin-bottom: 50px;
  border-radius: 20px;
  position: relative;
  background: var(--button-color);
  @media (max-width: 1028px) {
    // padding: 190px 0px 0px;
  }
  @media (max-width: 991px) {
    padding: 50px 0px 50px;
    margin-top: 120px;
  }
  @media (max-width: 540px) {
    padding: 40px 0px 40px;
    margin-top: 70px;
    margin-bottom: 30px;
  }
`;

const Title = styled.h1`
  z-index: 99;
  color: var(--font-color1);
  align-self: center;
  text-align: center;
  font-size: 80px;
  width: 100%;
//   padding: 15px 20px;
  @media (max-width: 1028px) {
    font-size: 65px;
    width: 100%;
  }
  @media (max-width: 991px) {
    width: 100%;
    font-size: 45px;
  }
  @media (max-width: 540px) {
    font-size: 36px;
    // padding: 10px 10px;
    margin: 0px;
    width: 100%;
    line-height: 40px;
}
`;

const Title1 = styled.h1`
  z-index: 99;
  letter-spacing: 12px;
  color: var(--font-color1);
  font-family: var(--desc-font-family);
  text-align: center;
  font-size: 18px;
  width: 100%;
  padding: 15px 20px;
  @media (max-width: 1028px) {
    letter-spacing: 10px;
  }
  @media (max-width: 991px) {
    width: 100%;
    letter-spacing: 6px;
  }
  @media (max-width: 540px) {
    letter-spacing: 2px;
    // font-size: 30px;
    padding: 20px 5px 10px;
    // margin-left: 3%; 
    font-size: 14px;
}
`;

const FooterContainer = styled.footer`
  display: flex;
  width: 100%;
  gap: 20px;
  font-size: 18px;
  align-items: center;
  flex-direction: column;
  @media (max-width: 991px) {
    gap: 10px;
    max-width: 100%;
    justify-content: flex-end;
  }
  @media (max-width: 540px) {
    flex-direction: column;
    margin-top: 0px;
    gap: 0px;
  }
`;

const LineCoin = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
@media (max-width: 1028px) {
    gap: 0px;
    // width: 70%;
}
@media (max-width: 991px) {
  max-width: 100%;
  width: 100%;
  margin-top: 0px;
}
@media (max-width: 540px) {
  width: 100%;
}
`;

const SocilMediaSection = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: space-between;
// padding-bottom: 30px;
@media (max-width: 1028px) {
  // width: 15%;
}
@media (max-width: 540px) {
  // width: 65%;
}
`;

const Image = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  max-width: 100%;
  // margin-top: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "72px" : "0")};
  cursor: pointer;
  @media (max-width: 991px) {
    margin-top: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "40px" : "0")};
  }
  @media (max-width: 540px) {
    height: 15px;
    width: 15px;
  }
`;

const Button = styled.button`
width: fit-content;
display: flex;
align-self: center;
background: var(--background-color);
padding: 10px 30px;
border-radius: 50px;
align-items: center;
margin-top: 30px;
@media (max-width: 991px) {
  margin-top: 15px;
}
@media (max-width: 540px) {
  font-size: 15px;
  padding: 10px 20px;
}
`;


export default MyComponent;