import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { telegramurl, twitterurl, dextool, uniswapurl, linktreeurl } from '../../url';
import WalletAddress from './WalletAddressButton'

function MyComponent() {
  const handleBuyNowClick = () => {
    window.open(uniswapurl, '_blank');
  };

  return (
    <MainSection>
      <ContentWrapper>
     
        <ImageColumn>
          <WalletBtn >
          <WalletAddress />
          </WalletBtn>
          <HeroImage src="/img/hero.png" alt="Hero Image" />
          <Button onClick={handleBuyNowClick}>
          Buy $MDOG Now
        </Button>
        </ImageColumn>
        {/* <ContentColumn>
          <ContentContainer> */}
        <TitleSpan> Welcome to </TitleSpan>
        <Title> McAfee's Dog
        meme world </Title>
        {/* </ContentContainer>
        </ContentColumn> */}
      </ContentWrapper>
    </MainSection>
  );
}

const WalletBtn = styled.div`
padding-top: 40px;
margin-bottom: -20px;
 display: flex;
 z-inedx: 9999999;
  @media (max-width: 1028px) {
    margin-bottom: 0px;
  }
   @media (max-width: 991px) {
    margin-bottom: 0px;
    padding-top: 0px;
    margin-top: -20px;
  }
`

const MainSection = styled.section`
  display: flex;
  min-height: 100vh;
  // background-image: url('/img/herobg.png'); 
  // background-size: cover; 
  // background-position: center; 
  // background-repeat: no-repeat;
  @media (max-width: 991px) {
    // flex-direction: column;
    // gap: 0;
    // justify-content: center;
  }
  @media (max-width: 540px) {
    min-height: 100vh;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
  @media (max-width: 540px) {
    align-items: center;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--button-color);
    clip-path: polygon(0 0, 100% 0%, 100% 60%, 0% 100%);
    z-index: -1; /* Ensure the background stays behind the content */
  }
`;

const Title = styled.h1`
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  color: var(--font-color1);
  font-size: var(--hero-font-size-desktop);
  line-height: 85px;
  font-family: var(--font-family);
  text-align: center;
  width: 40%;
  margin: auto;
  z-index: 99999;
  -webkit-text-stroke: 3px #081626;
  text-stroke: 1px #000; 
  @media (max-width: 1028px) {
    width: 85%;
    margin-bottom: 0px;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 50px; 
    text-align: center;
  }
  @media (max-width: 540px) {
    top: 70%;
    font-size: 40px; 
    line-height: 45px;
    text-align: center;
  }
`;

const TitleSpan = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-weight: 500;
  color: var(--font-color1);
  font-family: var(--font-family);
  text-align: center;
  font-size: 120px;
  z-index: -1;
  position: relative;
  @media (max-width: 540px) {
    top: 35%;
    font-size: 50px;
  }
`;

const ImageColumn = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9; 
  @media (max-width: 1028px) {
    width: 100%;
  }
  @media (max-width: 991px) {
    width: 90%;
    margin-left: 0px;
    align-items: center;
  }
  @media (max-width: 540px) {
    top: 55%;
    margin-left: 0px;
    width: 100%;
  }
`;

const HeroImage = styled.img`
  object-fit: contain;
  object-position: center;
  width: 32%;
  @media (max-width: 1028px) {
    object-fit: contain;
    width: 60%;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    width: 60%;
    margin-top: 0px;
    margin-bottom: 50px;
  }
  @media (max-width: 540px) {
    width: 80%;
    margin-bottom: 0px;
  }
`;

const Button = styled.button`
margin-top: -50px;
width: fit-content;
display: flex;
align-self: center;
background: var(--font-color1);
color: var(--background-color);
padding: 10px 30px;
border-radius: 50px;
align-items: center;
@media (max-width: 540px) {
    margin-top: 0px;
    font-size: 15px;
    padding: 10px 20px;
}
`;

export default MyComponent;