import * as React from "react";

import './App.css';
import HeaderSection from "./components/HeaderSection/HeaderSection";
import HeroSection from "./components/HeroSection/HeroSection";
import RoadmapSectoin from "./components/RoadmapSection/roadmapSection";
import AboutCoinSection from './components/AboutCoinSection/AboutCoinSection';
import TokenomicsSection from './components/TokenomicsSection/TokenomicsSection';
import CommunitySection from './components/Community/Community';
import FooterSection from './components/FooterSection/FooterSection';
import Gallery from "./components/Gallery/Gallery";
import Audio from "./components/Audio/Audio"

function App() {
  return (
    <div className="mainDiv">
      <HeaderSection />
      <HeroSection />
      <AboutCoinSection />
      <Gallery />
      <Audio />
      <RoadmapSectoin />
      <TokenomicsSection />
      <CommunitySection />
      <FooterSection />
    </div>

  );
}

export default App;