import * as React from "react";
import styled from "styled-components";

function Tokenomics() {
  return (
    <TokenomicsSection id="tokenomics">
      <TokenomicsTitle>Tokenomics</TokenomicsTitle>
      <TokenomicsImage
        loading="lazy"
        src="/img/tokenomics.png"
        alt="Tokenomics chart"
      />
    </TokenomicsSection>
  );
}

const TokenomicsSection = styled.section`
margin-top: 150px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 0 20px;
  color: var(--font-color1);
  font-size: 60px;
  font-weight: 400;
  align-items: center;
  @media (max-width: 1028px) {
    padding: 0px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
  }
  @media (max-width: 540px) {
    margin-top: 100px;
  }
`;

const TokenomicsTitle = styled.h2`
  width: 80%; 
  text-align: center;
  font-family: var(--font-family);
  font-size: 55px;
  line-height: 75px;
  margin-bottom: 40px;
  color: var(--font-color1);
  @media (max-width: 1028px) {
    font-size: 45px;
    line-height: 60px;
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
    // margin-bottom: 0px;
    line-height: 60px;
  }
  @media (max-width: 540px) {
    text-align: center;
    font-size: 28px;
    line-height: 35px;
    margin: 0px 0px 30px;
    width: 100%;
    padding: 0px 20px 0px;
  }
`;

const TokenomicsImage = styled.img`
  width: 45%;
  object-fit: contain;
  object-position: center;
  @media (max-width: 1028px) {
    width: 60%;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    height: auto;
  }
  @media (max-width: 540px) {
    width: 95%;
    margin-top: 0px;
  }
`;

export default Tokenomics;